import { useRouter } from 'next/router'
import { useCallback } from 'react'
import { useTrackingQueryStrings } from 'hooks/tracking'

const defaults = { shallow: true, shouldReset: false }

function cleanAndMergeQuery(source, target) {
  return Object.entries(source).reduce(
    (acc, [key, value]) => {
      if (typeof value === 'undefined') {
        delete acc[key]
      } else {
        acc[key] = value
      }

      return acc
    },
    { ...target },
  )
}

export function useUpdateRouterQuery() {
  const router = useRouter()
  const keepTrackingQueryStrings = useTrackingQueryStrings()

  return useCallback(
    (queryObject, { shallow, shouldReset, shouldReplace = true } = defaults) => {
      const method = shouldReplace ? router.replace : router.push
      const newQuery = cleanAndMergeQuery(queryObject, shouldReset ? {} : router.query)
      const url = keepTrackingQueryStrings({
        pathname: router.pathname,
        query: newQuery,
      })

      method(url, undefined, { shallow })
    },
    // Ignore router dependency due to unstable reference
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [router.query, router.pathname],
  )
}
