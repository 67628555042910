import React, { useState } from 'react'
import Sticky from 'react-stickynode'
import { useMediaQuery } from '@vizeat/components/es6/components/MediaQuery'
import { Box } from '@vizeat/components/es6/components/Box'
import { SearchForm } from '../search'
import PropTypes from 'helpers/proptypes'
import { css } from 'styled-components'

export function HomeSearchForm({ isMobileHeaderSearchVisible }) {
  const isPhabletOrHigher = useMediaQuery('phablet', true)
  const [stickyWrapperStatus, setStickyWrapperStatus] = useState(Sticky.STATUS_ORIGINAL)
  const isStickyWrapperFixed = stickyWrapperStatus === Sticky.STATUS_FIXED

  return (
    <Box
      m={{ default: '14px 0 0', phablet: '14px auto 0' }}
      width={{ default: '100%', phablet: '70%', desktop: '100%' }}
      opacity={{
        default: isMobileHeaderSearchVisible ? '0' : '1',
        tablet: '1',
      }}
      css={`
        transition: opacity 0.2s ease;
      `}
    >
      <Sticky
        top={10}
        enabled={isPhabletOrHigher}
        innerZ={isPhabletOrHigher ? 401 : null}
        onStateChange={({ status }) => setStickyWrapperStatus(status)}
      >
        <SearchForm
          name='homeSearchForm'
          withFilters={false}
          shouldUpdateRouter={false}
          shouldDisplaySearchIconOnMobile
          formStyles={
            isStickyWrapperFixed
              ? css`
                  box-shadow: ${({ theme }) => theme.shadows.md};
                `
              : undefined
          }
        />
      </Sticky>
    </Box>
  )
}

HomeSearchForm.propTypes = {
  isMobileHeaderSearchVisible: PropTypes.bool,
}

HomeSearchForm.defaultProps = {
  isMobileHeaderSearchVisible: false,
}
