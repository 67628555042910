import React, { memo } from 'react'
import { Badge } from '@vizeat/components/es6/components/Badge'
import PropTypes from 'helpers/proptypes'
import styled from 'styled-components'
import { useMultiFiltersToggle } from '../hooks/useMultiFiltersToggle'
import { StyledTitle, StyledFiltersList, StyledFiltersSection } from './styled'
import { AmenityIcon } from 'components/shared/icons/AmenityIcon'
import { useTranslation } from 'next-i18next'
import { useFilteredData } from '../hooks/useFilteredData'
import { useAmenitiesQuery } from 'hooks/queries/categories/useCategoriesQuery'

const StyledBadge = styled((props) => <Badge as='li' role='button' tabIndex={0} {...props} />)`
  cursor: pointer;
  border: ${({ theme, isSelected }) =>
    `${theme.borders.sm} ${isSelected ? theme.colors.eatwithOrange : theme.colors.lightGray}`};
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme, isSelected }) => (isSelected ? theme.colors.eatwithOrange : null)};
  border-radius: ${({ theme }) => theme.radii.md};
  padding: 8px;
  word-break: break-word;
  min-height: 70px;
`

export const Amenities = memo(function Amenities({ selectedValues, onChange }) {
  const { t } = useTranslation()
  const handleChange = useMultiFiltersToggle(selectedValues, {
    onChange,
    filterName: 'amenities',
    gtmFilterName: 'accommodation',
  })

  const amenities = useAmenitiesQuery()
  const filteredAmenities = useFilteredData({
    data: amenities,
  })

  return (
    <StyledFiltersSection>
      <StyledTitle>{t('Search::Accommodation')}</StyledTitle>
      <StyledFiltersList isGrid forwardedAs='ul' gap='8px'>
        {filteredAmenities.map(({ originalTitle, localizedTitle, id, picto }) => (
          <StyledBadge
            key={id}
            onClick={() => {
              handleChange(null, originalTitle)
            }}
            isSelected={selectedValues.includes(originalTitle)}
          >
            <AmenityIcon picto={picto} size='24px' mr='12px' />
            {localizedTitle}
          </StyledBadge>
        ))}
      </StyledFiltersList>
    </StyledFiltersSection>
  )
})

Amenities.propTypes = {
  selectedValues: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired,
}

Amenities.defaultProps = {
  selectedValues: [],
}
