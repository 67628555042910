import { IconChevronDown, IconChevronUp } from '@vizeat/components/es6/assets/icons'
import { Flex } from '@vizeat/components/es6/components/Flex'
import { useToggle } from '@vizeat/components/es6/hooks'
import PropTypes from 'helpers/proptypes'
import { useTranslation } from 'next-i18next'
import React, { useMemo, useEffect } from 'react'

export function ExpandableList({ list, children, maxVisibleItems }) {
  const { t } = useTranslation()
  const canShowMore = list.length > maxVisibleItems

  const [showMore, { toggle, setValue }] = useToggle(canShowMore)
  useEffect(() => {
    setValue(canShowMore)
  }, [canShowMore, setValue])

  const visibleList = useMemo(
    () => (showMore ? list.slice(0, maxVisibleItems) : list),
    [showMore, list, maxVisibleItems],
  )

  const IconChevron = showMore ? IconChevronDown : IconChevronUp

  return (
    <>
      {children(visibleList)}

      {canShowMore && (
        <Flex
          justifyContent='flex-end'
          alignItems='center'
          role='button'
          onClick={toggle}
          mt='24px'
          color='eatwithOrange'
          fontWeight='bolder'
          css={`
            cursor: pointer;
            text-decoration: underline;

            &:hover {
              text-decoration: none;
            }
          `}
        >
          {showMore ? t('ExpandableList::see more') : t('ExpandableList::see less')}
          <IconChevron direction='forward' ml='4px' fontSize='16px' />
        </Flex>
      )}
    </>
  )
}

ExpandableList.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  children: PropTypes.func.isRequired,
  maxVisibleItems: PropTypes.number.isRequired,
}
