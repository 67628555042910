import React, { useEffect, useState } from 'react'
import { Header } from 'layouts/Header'
import { Box } from '@vizeat/components/es6/components/Box'
import { throttle } from 'lodash'
import PropTypes from 'helpers/proptypes'
import { SearchForm } from '../search'

const SCROLL_OFFSET = 20
const SEARCH_FORM_OFFSET = 360

export function MobileHeader({ onShowSearchChange, shouldShowSearch }) {
  const [isTransparent, setIsTransparent] = useState(true)

  useEffect(() => {
    const handleScroll = throttle(() => {
      setIsTransparent(window.scrollY < SCROLL_OFFSET)
      onShowSearchChange(window.scrollY >= SEARCH_FORM_OFFSET)
    }, 10)

    window.addEventListener('scroll', handleScroll)
    window.addEventListener('resize', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
      window.removeEventListener('resize', handleScroll)
    }
  }, [onShowSearchChange])

  return (
    <Box position='fixed' top='0' left='0' right='0' zIndex='402'>
      <Header
        showSearch={shouldShowSearch}
        isTransparent={isTransparent}
        logoSize={shouldShowSearch ? '47px' : undefined}
        SearchBarComponent={<SearchForm withFilters={false} shouldUpdateRouter={false} />}
      />
    </Box>
  )
}

MobileHeader.propTypes = {
  onShowSearchChange: PropTypes.func.isRequired,
  shouldShowSearch: PropTypes.bool.isRequired,
}
