import React from 'react'
import { StyledSectionTitle, StyledSection } from './styled'
import { CarouselRtlSensitive } from 'components/shared/carousels'
import { usePlaylists } from 'hooks/search/usePlaylist'
import PropTypes from 'helpers/proptypes'
import { scaleCropFile } from 'helpers/ImageManager'
import { Flex } from '@vizeat/components/es6/components/Flex'
import styled from 'styled-components'
import { SmartLink } from 'components/shared/SmartLink'
import { useMediaQuery } from '@vizeat/components/es6/components/MediaQuery'
import { rgba } from 'polished'

const DEFAULT_COVER = 1518335

const StyledCard = styled(({ fileId, ...props }) => (
  <Flex
    height={213}
    background={`url(${scaleCropFile({ fileId, size: '232x213' })}) no-repeat 0 0`}
    backgroundSize='cover'
    bg='lightGray'
    color='white'
    p='12px'
    fontSize='28px'
    lineHeight='1'
    fontWeight='bold'
    alignItems='center'
    textAlign='center'
    justifyContent='center'
    position='relative'
    zIndex={1}
    borderRadius='xl'
    overflow='hidden'
    {...props}
  />
))`
  transition: color 0.2s ease;

  a&:after {
    content: '';
    position: absolute;
    z-index: -1;
    pointer-events: none;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: ${({ theme }) => rgba(theme.colors.black, 0.24)};
    transition: background 0.3s ease;
  }

  a&:hover:after {
    background: ${({ theme }) => rgba(theme.colors.black, 0.5)};
  }
`

//! TODO expose the following skeleton styles from the components-library
const StyledSkeletonCard = styled(StyledCard)`
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: linear-gradient(
      100deg,
      rgba(255, 255, 255, 0) 40%,
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 60%
    );
    background-size: 200% 100%;
    background-position-x: 180%;
    animation: 1s loading ease-in-out infinite;
  }

  &:after {
    content: '';
    display: block;
    width: 70%;
    height: 35px;
    background: ${({ theme }) => theme.colors.lighterGray};
  }

  @keyframes loading {
    to {
      background-position-x: -20%;
    }
  }
`

export function AboutSection({ title, items }) {
  const playlistsQueryResult = usePlaylists(items.map((item) => Number(item.playlist_id)))
  const isTabletOrHigher = useMediaQuery('tablet')

  if (items.length === 0) return null

  function renderPlaylistItem({ data, isError }) {
    if (isError) return null

    if (data.placeholderId) {
      return <StyledSkeletonCard key={data.placeholderId} />
    }

    const { id, cover_id: fileId, title: playlistTitle } = data
    const playlistTitleFromPrismic = items.find((item) => Number(item.playlist_id) === id)?.playlist_title

    return (
      <StyledCard key={id} forwardedAs={SmartLink} href={`/search?playlist=${id}`} fileId={fileId || DEFAULT_COVER}>
        {playlistTitleFromPrismic || playlistTitle}
      </StyledCard>
    )
  }

  return (
    <StyledSection hasCarousel>
      <StyledSectionTitle showCustomMarks>{title}</StyledSectionTitle>
      <CarouselRtlSensitive
        slideWidth='232px'
        cellSpacing={isTabletOrHigher ? 24 : 12}
        carouselStyles='user-select: auto'
      >
        {playlistsQueryResult.map(renderPlaylistItem)}
      </CarouselRtlSensitive>
    </StyledSection>
  )
}

AboutSection.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      playlist_id: PropTypes.string,
      playlist_title: PropTypes.string,
    }),
  ).isRequired,
}
