import React, { memo } from 'react'
import PropTypes from 'helpers/proptypes'
import { useTranslation } from 'next-i18next'
import { Checkbox } from '@vizeat/components/es6/components/Checkbox/Checkbox'
import { StyledFiltersList, StyledFiltersSection, StyledTitle } from './styled'
import { gtmFilterSelected } from 'gtm'

export const EventType = memo(function EventType({ allowPublicBookings, allowPrivateBookings, online, onChange }) {
  const { t } = useTranslation()

  function handleAllowPublicBookingsChange(e) {
    // true is the default value so we don't need to update the router
    onChange({ [e.target.name]: e.target.checked ? undefined : false })
    gtmFilterSelected(e.target.name, e.target.checked)
  }

  function handleChange(e) {
    onChange({ [e.target.name]: e.target.checked || undefined })
    gtmFilterSelected(e.target.name, e.target.checked)
  }

  return (
    <StyledFiltersSection>
      <StyledTitle>{t('Search::Type of event')}</StyledTitle>

      <StyledFiltersList gap='28px'>
        <Checkbox
          id='allowPublicBookings'
          label={t('Search::Public event')}
          name='allowPublicBookings'
          checked={allowPublicBookings}
          onChange={handleAllowPublicBookingsChange}
          mt='0'
        />

        <Checkbox
          id='allowPrivateBookings'
          label={t('Search::Private event')}
          name='allowPrivateBookings'
          checked={allowPrivateBookings}
          onChange={handleChange}
          mt='0'
        />

        <Checkbox
          id='online'
          label={t('Search::Online Experiences')}
          name='online'
          checked={online}
          onChange={handleChange}
          mt='0'
        />
      </StyledFiltersList>
    </StyledFiltersSection>
  )
})

EventType.propTypes = {
  onChange: PropTypes.func.isRequired,
  allowPublicBookings: PropTypes.bool,
  allowPrivateBookings: PropTypes.bool,
  online: PropTypes.bool,
}

EventType.defaultProps = {
  allowPublicBookings: false,
  allowPrivateBookings: false,
  online: false,
}
