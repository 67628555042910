import { COOKIE_KEYS, loadCookie, saveCookie } from 'helpers/cookies'
import { DEFAULT_SEATS, getDefaultSearchDateRange } from 'helpers/search'
import moment from 'moment'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTrackingQueryStrings } from 'hooks/tracking'

function updateSearchCookie(query) {
  const prevSearches = loadCookie(COOKIE_KEYS.RECENT_SEARCHES) || []
  const filteredPrevSearches = prevSearches
    .filter((search) => JSON.stringify(search) !== JSON.stringify(query)) // remove existing prev search
    .filter((search) => (search.dateto ? !moment(search.dateto).isBefore(moment.utc(), 'day') : true)) // remove past date searches

  const recentSearches = [query, ...filteredPrevSearches]
  saveCookie({ name: COOKIE_KEYS.RECENT_SEARCHES, value: recentSearches.slice(0, 5) })
}

function getState({ q = '', pid, datefrom, dateto, nbguests } = {}) {
  const defaultDateRange = getDefaultSearchDateRange()

  return {
    searchQuery: { q, pid },
    dateRange: {
      from: datefrom || defaultDateRange.start,
      to: dateto || defaultDateRange.end,
    },
    numberOfSeats: Number(nbguests || DEFAULT_SEATS),
  }
}

export function useSearchForm() {
  const router = useRouter()
  const { q, pid, datefrom, dateto, nbguests, playlist } = router.query
  const [state, setFormState] = useState(getState())
  const { searchQuery, dateRange, numberOfSeats } = state
  const keepTrackingQueryStrings = useTrackingQueryStrings()

  useEffect(() => {
    setFormState((prev) => ({
      ...prev,
      ...getState({ q, pid, datefrom, dateto, nbguests }),
    }))
  }, [datefrom, dateto, nbguests, q, pid])

  const handleChange = useCallback(({ name, value }) => {
    setFormState((prev) => ({
      ...prev,
      [name]: value,
    }))
  }, [])

  const handleReset = useCallback(() => {
    setFormState(getState())
  }, [])

  const query = useMemo(
    () =>
      keepTrackingQueryStrings({
        ...(playlist && { playlist }),
        ...searchQuery,
        ...(numberOfSeats && { nbguests: numberOfSeats }),
        ...(dateRange.from && { datefrom: dateRange.from }),
        ...(dateRange.to && { dateto: dateRange.to }),
      }),
    [dateRange.from, dateRange.to, keepTrackingQueryStrings, numberOfSeats, playlist, searchQuery],
  )

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault()

      updateSearchCookie(query)
      router.push({ pathname: '/search', query }, undefined, { shallow: true })
    },
    // Ignore unstable router dep
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dateRange.from, dateRange.to, numberOfSeats, searchQuery, playlist, handleReset, query],
  )

  return useMemo(
    () => ({
      handleSubmit,
      handleChange,
      handleReset,
      state,
      query,
    }),
    [handleSubmit, handleChange, handleReset, state, query],
  )
}
