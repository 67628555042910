import React, { useCallback, useRef } from 'react'
import PropTypes from 'helpers/proptypes'
import { NumericInput } from '@vizeat/components/es6/components/NumericInput'
import { useTranslation } from 'next-i18next'
import { Dropdown } from '@vizeat/components/es6/components/Dropdown'
import { Flex } from '@vizeat/components/es6/components/Flex'
import { StyledField, StyledPlaceholder } from './styled'
import { useMediaQuery } from '@vizeat/components/es6/components/MediaQuery'

const MIN = 0
const MAX = 100

export function GuestsField({ numberOfSeats, onChange }) {
  const { t } = useTranslation()
  const inputRef = useRef(null)
  const isTabletOrHigher = useMediaQuery('tablet')

  const handleChange = useCallback(
    (value) => {
      if (value < MIN || value > MAX) return
      onChange({
        name: 'numberOfSeats',
        value,
      })
    },
    [onChange],
  )

  const handleFocus = useCallback(() => {
    inputRef.current?.focus()
  }, [])

  const Trigger = (
    <StyledField borderRight={{ tablet: 0 }}>
      {numberOfSeats ? (
        t('HomePage::SearchForm::{{count}} guest', { count: numberOfSeats })
      ) : (
        <StyledPlaceholder>{t('SearchForm::Guest')}</StyledPlaceholder>
      )}
    </StyledField>
  )

  if (!isTabletOrHigher) return Trigger

  return (
    <Dropdown
      id='guests-dropdown'
      onOpen={handleFocus}
      dropdownStyles='bottom: -20px;'
      position='center'
      shouldFocus={false}
      Trigger={Trigger}
    >
      <Flex width='340px' justifyContent='center' p='24px 18px'>
        <NumericInput min={MIN} max={MAX} value={numberOfSeats} onChange={handleChange} ref={inputRef} />
      </Flex>
    </Dropdown>
  )
}

GuestsField.propTypes = {
  numberOfSeats: PropTypes.number,
  onChange: PropTypes.func.isRequired,
}

GuestsField.defaultProps = {
  numberOfSeats: undefined,
}
