import React, { memo, useCallback } from 'react'
import PropTypes from 'helpers/proptypes'
import { useTranslation } from 'next-i18next'
import { RangeSlider } from '@vizeat/components/es6/components/RangeSlider'
import { useMakePriceFormatter } from 'hooks/price/usePriceFormatter'
import { StyledFiltersSection, StyledTitle } from './styled'

const LIMIT_MIN = 1
const LIMIT_MAX = 100

export const Price = memo(function Price({ min, max, onChange }) {
  const { t } = useTranslation()
  const priceFormatter = useMakePriceFormatter()

  const formatPrice = useCallback(
    (price) => {
      const priceToFormat = Math.min(price, LIMIT_MAX)
      const isLimitMaxPrice = priceToFormat === LIMIT_MAX
      const formattedPrice = priceFormatter({ price: priceToFormat * 100 })
      return isLimitMaxPrice ? `${formattedPrice}+` : formattedPrice
    },
    [priceFormatter],
  )

  const handleChange = useCallback(
    (priceMin, priceMax) => {
      onChange({ priceMin, priceMax: priceMax === LIMIT_MAX ? 9999 : priceMax })
    },
    [onChange],
  )

  return (
    <StyledFiltersSection alignItems='center'>
      <StyledTitle>{t('Search::Price')}</StyledTitle>
      <RangeSlider
        pb={{ default: 0, tablet: '16px' }}
        m='0 auto'
        width={{ default: '100%', tablet: '80%' }}
        minValue={min}
        maxValue={max}
        limitMin={LIMIT_MIN}
        limitMax={LIMIT_MAX}
        renderMinValueInfo={formatPrice}
        renderMaxValueInfo={formatPrice}
        onValueChange={handleChange}
      />
    </StyledFiltersSection>
  )
})

Price.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  onChange: PropTypes.func.isRequired,
}

Price.defaultProps = {
  min: LIMIT_MIN,
  max: LIMIT_MAX,
}
