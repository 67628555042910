import React, { useCallback, useState, useRef, useMemo, useEffect } from 'react'
import PropTypes from 'helpers/proptypes'
import { MobileModal, MobileModalTitle } from '@vizeat/components/es6/components/MobileModal'
import { useTranslation } from 'next-i18next'
import moment from 'moment'
import { OpenSearchInput } from 'components/shared/inputs'
import { Box } from '@vizeat/components/es6/components/Box'
import { MultiDayPicker } from '@vizeat/components/es6/components/MultiDayPicker'
import { Select } from '@vizeat/components/es6/components/Select'
import { isValidDay } from 'helpers/date'
import styled, { css } from 'styled-components'
import { Heading } from '@vizeat/components/es6/components/Heading'
import { Flex } from '@vizeat/components/es6/components/Flex'
import { Grid } from '@vizeat/components/es6/components/Grid'
import { useSearchForm } from 'hooks/search/useSearchForm'
import { StyledButton } from './styled'

const styledModalContentProps = {
  padding: '24px 16px',
  bg: 'lighterGray',
}

const selectSeatsContainerStyles = { marginTop: 0, zIndex: 11, position: 'relative' }

const searchInputStyles = css`
  appearance: none;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.lightGray};
  border-radius: ${({ theme }) => theme.radii.lg};
  padding: 8px;
  height: 45px;
  font-size: 16px;
  width: 100%;
  &::placeholder {
    color: ${({ theme }) => theme.colors.black};
  }
`

const StyledMultiDayPickerWrapper = styled((props) => <Box bg='white' borderRadius='lg' {...props} />)`
  .DayPicker .DayPicker-wrapper {
    max-width: 450px;
    margin: 0 auto;
    padding: 0;
  }

  .DayPicker .DayPicker-Month {
    margin-top: 24px;
  }
`

export function MobileSearchFormModal({ isVisible, onHide }) {
  const { t } = useTranslation()
  const submitBtnRef = useRef(null)
  const [searchInput, setSearchInput] = useState('')
  const {
    handleSubmit,
    handleChange,
    handleReset,
    state: { searchQuery, dateRange, numberOfSeats },
  } = useSearchForm()

  useEffect(() => {
    if (!isVisible) {
      setSearchInput('')
      handleReset()
    }
  }, [isVisible, handleReset])

  const { month, disabledDays } = useMemo(
    () => ({
      month: moment().utc(),
      disabledDays: {
        before: moment().add(6, 'hours'),
      },
    }),
    [],
  )

  const mDateRange = useMemo(
    () => ({
      from: dateRange.from ? moment.utc(dateRange.from) : undefined,
      to: dateRange.to ? moment.utc(dateRange.to) : undefined,
    }),
    [dateRange.from, dateRange.to],
  )

  const seatsOptions = useMemo(
    () =>
      Array.from({ length: 100 }, (_, i) => ({
        value: i + 1,
        label: t('HomePage::SearchForm::{{count}} guest', { count: i + 1 }),
      })),
    [t],
  )

  const handlePlaceChange = useCallback(
    (place) => {
      handleChange({
        name: 'searchQuery',
        value: {
          q: place.title || '',
          pid: place.id,
        },
      })

      submitBtnRef.current?.focus()
    },
    [handleChange],
  )

  const handleDatesChange = useCallback(
    ({ from, to }) => {
      handleChange({
        name: 'dateRange',
        value: {
          from: from?.format('YYYY-MM-DD'),
          to: to?.format('YYYY-MM-DD'),
        },
      })
    },
    [handleChange],
  )

  const handleNumberOfSeatsChange = useCallback(
    ({ value }) => {
      handleChange({
        name: 'numberOfSeats',
        value,
      })
    },
    [handleChange],
  )

  return (
    <MobileModal
      show={isVisible}
      onHide={onHide}
      Header={<MobileModalTitle content={t('HomePage::SearchForm::Button::Search')} />}
      variant='default'
      styledContentProps={styledModalContentProps}
    >
      {({ handleModalHide }) => (
        <Flex
          as='form'
          flexDirection='column'
          onSubmit={(e) => {
            handleSubmit(e)
            handleModalHide()
          }}
          gap='24px'
        >
          <OpenSearchInput
            onChange={setSearchInput}
            onPlaceSelect={handlePlaceChange}
            placeholder={t('HomePage::SearchForm::Label::Search for cities, hosts or experiences')}
            showNearbyExperiences
            showRecentSearches
            value={searchInput}
            width='100%'
            zIndex={5}
            name='search'
            triggerInputCss={searchInputStyles}
            onRecentSearchClick={handleModalHide}
          />

          <Select
            containerStyles={selectSeatsContainerStyles}
            isSearchable={false}
            label={t('EventPage::RequestPE::Number of guests')}
            options={seatsOptions}
            value={numberOfSeats}
            onChange={handleNumberOfSeatsChange}
          />

          <Box>
            <Heading
              forwardedAs='h2'
              type='small'
              css={`
                margin-bottom: 18px;
              `}
            >
              {t('EventPage::FormCard::SelectDatesLabel::Date of event')}
            </Heading>
            <StyledMultiDayPickerWrapper>
              <MultiDayPicker
                initialMonth={month}
                disabledDays={disabledDays}
                isValidDate={isValidDay}
                onChange={handleDatesChange}
                from={mDateRange.from}
                to={mDateRange.to}
              />
            </StyledMultiDayPickerWrapper>
          </Box>

          <Grid padding='0 24px' gap='8px' templateColumns='1fr 1fr'>
            <StyledButton type='button' clearPrimary large onClick={handleReset}>
              {t('Forms::Reset')}
            </StyledButton>
            <StyledButton type='submit' disabled={!searchQuery.q} green large>
              {t('HomePage::SearchForm::Button::Search')}
            </StyledButton>
          </Grid>
        </Flex>
      )}
    </MobileModal>
  )
}

MobileSearchFormModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
}
