import { useMemo } from 'react'

function toLocalizedData({ title, original_title: originalTitle, translation, id, picto, metadata }) {
  return {
    localizedTitle: translation || title,
    title,
    originalTitle,
    id,
    picto: picto || metadata?.picto || null,
  }
}

function getCount(item, facets) {
  return facets.get(item.originalTitle || item.title) || 0
}

export function useFilteredData({ data = [], facets } = {}) {
  const localizedData = useMemo(() => data.map(toLocalizedData), [data])

  return useMemo(() => {
    if (!facets) return localizedData

    return [...localizedData]
      .map((item) => ({
        ...item,
        count: getCount(item, facets),
      }))
      .sort((a, b) => b.count - a.count)
      .filter(({ count }) => count > 0)
  }, [facets, localizedData])
}
