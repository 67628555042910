import React, { memo } from 'react'
import PropTypes from 'helpers/proptypes'
import { useTranslation } from 'next-i18next'
import { Checkbox } from '@vizeat/components/es6/components/Checkbox/Checkbox'
import { Map } from 'immutable'
import { useMultiFiltersToggle } from '../hooks/useMultiFiltersToggle'
import { StyledFiltersList, StyledFiltersSection, StyledTitle } from './styled'
import { ExpandableList } from 'components/shared/ExpandableList'
import { useFilteredData } from '../hooks/useFilteredData'
import { useFoodsQuery } from 'hooks/queries/categories/useCategoriesQuery'

export const FoodType = memo(function FoodType({ facets, selectedValues, onChange }) {
  const { t } = useTranslation()
  const handleChange = useMultiFiltersToggle(selectedValues, { onChange, filterName: 'foods' })
  const foods = useFoodsQuery()

  const filteredFoods = useFilteredData({
    data: foods,
    facets,
  })

  if (filteredFoods.length === 0) return null

  return (
    <StyledFiltersSection>
      <StyledTitle>{t('Search::Food Type')}</StyledTitle>
      <ExpandableList list={filteredFoods} maxVisibleItems={12}>
        {(visibleList) => (
          <StyledFiltersList isGrid>
            {visibleList.map(({ title, originalTitle }) => {
              return (
                <Checkbox
                  key={`food-type-${originalTitle}`}
                  id={`food-type-${originalTitle}`}
                  label={title}
                  name={originalTitle}
                  checked={selectedValues.includes(originalTitle)}
                  onChange={handleChange}
                  mt='0'
                />
              )
            })}
          </StyledFiltersList>
        )}
      </ExpandableList>
    </StyledFiltersSection>
  )
})

FoodType.propTypes = {
  facets: PropTypes.immutable.map,
  selectedValues: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired,
}

FoodType.defaultProps = {
  facets: Map({}),
  selectedValues: [],
}
