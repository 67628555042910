import React, { memo } from 'react'
import PropTypes from 'helpers/proptypes'
import { useTranslation } from 'next-i18next'
import { Checkbox } from '@vizeat/components/es6/components/Checkbox/Checkbox'
import { Map } from 'immutable'
import { useQuery } from '@tanstack/react-query'
import { getLanguages } from 'api/languages'
import { useMultiFiltersToggle } from '../hooks/useMultiFiltersToggle'
import { StyledFiltersList, StyledFiltersSection, StyledTitle } from './styled'
import { ExpandableList } from 'components/shared/ExpandableList'
import { useFilteredData } from '../hooks/useFilteredData'

export const Language = memo(function Language({ facets, selectedValues, onChange }) {
  const { t } = useTranslation()
  const handleChange = useMultiFiltersToggle(selectedValues, { onChange, filterName: 'languages' })

  const { data: languages = [] } = useQuery(['languages'], getLanguages, {
    staleTime: 1000 * 60 * 60 * 24,
  })

  const filteredLanguages = useFilteredData({
    data: languages,
    facets,
  })

  if (filteredLanguages.length === 0) return null

  return (
    <StyledFiltersSection>
      <StyledTitle>{t('Search::Host Language')}</StyledTitle>
      <ExpandableList list={filteredLanguages} maxVisibleItems={12}>
        {(visibleList) => (
          <StyledFiltersList isGrid>
            {visibleList.map(({ title, originalTitle }) => {
              return (
                <Checkbox
                  key={`language-${originalTitle}`}
                  id={`language-${originalTitle}`}
                  label={title}
                  name={originalTitle}
                  checked={selectedValues.includes(originalTitle)}
                  onChange={handleChange}
                  mt='0'
                />
              )
            })}
          </StyledFiltersList>
        )}
      </ExpandableList>
    </StyledFiltersSection>
  )
})

Language.propTypes = {
  facets: PropTypes.immutable.map,
  selectedValues: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired,
}

Language.defaultProps = {
  facets: Map({}),
  selectedValues: [],
}
