import React from 'react'
import PropTypes from 'helpers/proptypes'
import * as icons from '@vizeat/components/es6/assets/icons'
import { konstants } from '@vizeat/helpers'

const { AMENITIES } = konstants

const AMENITIESICONS = {
  [AMENITIES.KID]: 'IconKid',
  [AMENITIES.WEELCHAIR]: 'IconWeelchair',
  [AMENITIES.ELEVATOR]: 'IconElevator',
  [AMENITIES.SMOKING]: 'IconCigarette',
  [AMENITIES.FOOTPRINT]: 'IconFootprint',
  [AMENITIES.SNOWFLAKE]: 'IconSnowflake',
  [AMENITIES.FREE_PARKING]: 'IconFreeParking',
  [AMENITIES.STREET_PARKING]: 'IconStreetParking',
  [AMENITIES.TAXI]: 'IconTaxi',
  [AMENITIES.PUBLIC_TRANSPORT]: 'IconPublicTransport',
  [AMENITIES.PIN]: 'IconPin',
  [AMENITIES.BACK_ARROW]: 'IconBackArrow',
  [AMENITIES.WIFI]: 'IconWifi',
}

export const AmenityIcon = React.memo(function _AmenityIcon({ picto, ...props }) {
  const IconComponent = icons[AMENITIESICONS[picto]]

  return <IconComponent {...props} />
})

AmenityIcon.propTypes = {
  picto: PropTypes.string.isRequired,
}

AmenityIcon.displayName = 'AmenityIcon'
