import React from 'react'
import { Box } from '@vizeat/components/es6/components/Box'
import { Heading } from '@vizeat/components/es6/components/Heading'
import styled from 'styled-components'
import { Flex } from '@vizeat/components/es6/components/Flex'
import { Grid } from '@vizeat/components/es6/components/Grid'

export const StyledTitle = styled(Heading)`
  font-size: 20px;
  margin-bottom: 24px;
`
export const StyledFiltersSection = styled(Box)`
  border-top: ${({ theme }) => `${theme.borders.sm} ${theme.colors.lightGray}`};
  margin: 24px 0;
  padding: 24px 0 0;

  &:first-of-type {
    margin-top: 0;
    border-top: 0;
    padding-top: 12px;
  }

  ${({ theme }) => theme.media.tablet`
    margin: 24px;
    padding: 24px 20px 0;
`}

  ${({ theme }) => theme.media.desktop`
    padding: 24px 40px 0;
    margin: 24px 60px;
`}
`

export const StyledFiltersList = styled(({ isGrid, ...props }) => {
  return isGrid ? (
    <Grid templateColumns={{ default: 'repeat(2, 1fr)', tablet: 'repeat(4, 1fr)' }} {...props} />
  ) : (
    <Flex flexDirection='column' {...props} />
  )
})`
  font-size: 14px;
  padding: 0 8px;
  gap: ${({ gap = '28px 14px' }) => gap};

  ${({ theme }) => theme.media.tablet`
  padding: 0 24px;
`}
`
