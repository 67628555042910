import React from 'react'
import { StyledSection, StyledSectionTitle, StyledSectionWrapper } from './styled'
import { EventsSearchSection } from 'components/shared/sections'
import PropTypes from 'helpers/proptypes'
import { useSearchQuery } from 'hooks/search/useSearchQuery'

export function HomeEventsSearchSection({ title, variant, gtmType, searchPayload, searchOptions, showWrapper }) {
  const { selectData } = useSearchQuery(searchPayload, searchOptions)
  const events = selectData(({ events }) => events)

  if (events.size === 0) return null

  const SectionElement = (
    <StyledSection hasCarousel>
      <StyledSectionTitle>{title}</StyledSectionTitle>
      <EventsSearchSection
        gtmType={gtmType}
        variant={variant}
        searchPayload={searchPayload}
        searchOptions={searchOptions}
      />
    </StyledSection>
  )

  if (showWrapper) return <StyledSectionWrapper>{SectionElement}</StyledSectionWrapper>
  return SectionElement
}

HomeEventsSearchSection.propTypes = {
  title: PropTypes.string.isRequired,
  searchPayload: PropTypes.shape({}).isRequired,
  searchOptions: PropTypes.shape({}),
  variant: PropTypes.oneOf(['small']),
  gtmType: PropTypes.string,
  showWrapper: PropTypes.bool,
}

HomeEventsSearchSection.defaultProps = {
  variant: undefined,
  searchOptions: undefined,
  gtmType: undefined,
  showWrapper: false,
}
