import React, { memo } from 'react'
import PropTypes from 'helpers/proptypes'
import { useTranslation } from 'next-i18next'
import { Checkbox } from '@vizeat/components/es6/components/Checkbox/Checkbox'
import { Map } from 'immutable'
import { useMultiFiltersToggle } from '../hooks/useMultiFiltersToggle'
import { StyledFiltersList, StyledFiltersSection, StyledTitle } from './styled'
import { ExpandableList } from 'components/shared/ExpandableList'
import { useFilteredData } from '../hooks/useFilteredData'
import { useDietsQuery } from 'hooks/queries/categories/useCategoriesQuery'

export const DietRestrictions = memo(function DietRestrictions({ facets, selectedValues, onChange }) {
  const { t } = useTranslation()
  const handleChange = useMultiFiltersToggle(selectedValues, { onChange, filterName: 'diets' })

  const diets = useDietsQuery()

  const filteredDiets = useFilteredData({
    data: diets,
    facets,
  })

  if (filteredDiets.length === 0) return null

  return (
    <StyledFiltersSection>
      <StyledTitle>{t('Search::Special Diet')}</StyledTitle>
      <ExpandableList list={filteredDiets} maxVisibleItems={12}>
        {(visibleList) => (
          <StyledFiltersList isGrid>
            {visibleList.map(({ title }) => {
              return (
                <Checkbox
                  key={`diet-restriction-${title}`}
                  id={`diet-restriction-${title}`}
                  label={title}
                  name={title}
                  checked={selectedValues.includes(title)}
                  onChange={handleChange}
                  mt='0'
                />
              )
            })}
          </StyledFiltersList>
        )}
      </ExpandableList>
    </StyledFiltersSection>
  )
})

DietRestrictions.propTypes = {
  facets: PropTypes.immutable.map,
  selectedValues: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired,
}

DietRestrictions.defaultProps = {
  facets: Map({}),
  selectedValues: [],
}
