import React, { useCallback, useState, useRef } from 'react'
import PropTypes from 'helpers/proptypes'
import { IconSearch } from '@vizeat/components/es6/assets/icons'
import { Box } from '@vizeat/components/es6/components/Box'
import { RoundedIconWrapper } from 'components/shared/RoundedIconWrapper'
import { useTranslation } from 'next-i18next'
import { OpenSearchInput } from 'components/shared/inputs'
import { Dropdown } from '@vizeat/components/es6/components/Dropdown'
import { StyledField, StyledPlaceholder } from './styled'
import { useCombobox } from 'downshift'
import { useMediaQuery } from '@vizeat/components/es6/components/MediaQuery'

const openSearchStateReducer = (_, { changes, type }) => {
  switch (type) {
    case useCombobox.stateChangeTypes.InputKeyDownEnter:
    case useCombobox.stateChangeTypes.ItemClick:
      return {
        ...changes,
        inputValue: '', // don't add the item string as input value at selection.
      }
    default:
      return changes
  }
}

export function PlaceField({ searchQuery, onChange }) {
  const { t } = useTranslation()
  const [inputValue, setInputValue] = useState('')
  const inputRef = useRef(null)
  const isTabletOrHigher = useMediaQuery('tablet')

  const handleFocus = useCallback(() => {
    inputRef.current?.focus()
  }, [])

  const renderSearchTrigger = useCallback(({ ref, ...inputProps }, { ref: comboboxRef }) => {
    const handleRef = (node) => {
      inputRef.current = node
      ref(node)
    }

    return (
      <Box position='relative' mb='18px' ref={comboboxRef}>
        <Box
          as='input'
          border='0'
          bg='lighterGray'
          borderRadius='full'
          width='100%'
          padding='8px 36px 8px 12px'
          css={`
            &:focus {
              outline: ${({ theme }) => `${theme.borders.sm} ${theme.colors.superPaleOrange}`};
            }
          `}
          ref={handleRef}
          {...inputProps}
        />
        <RoundedIconWrapper
          bg='eatwithOrange'
          color='white'
          width='28px'
          height='28px'
          position='absolute'
          right='4px'
          top='4px'
          css={`
            pointer-events: none;
          `}
        >
          <IconSearch />
        </RoundedIconWrapper>
      </Box>
    )
  }, [])

  const Trigger = (
    <StyledField
      hideSeparator
      width={{ default: '100%', medium: 'auto' }}
      fontWeight={{ default: 'bold', tablet: 'medium' }}
    >
      {searchQuery.q || <StyledPlaceholder>{t('SearchForm::Where ?')}</StyledPlaceholder>}
    </StyledField>
  )

  if (!isTabletOrHigher) return Trigger

  return (
    <Dropdown
      id='place-dropdown'
      shouldFocus={false}
      position='center'
      dropdownStyles='bottom: -20px; padding: 24px 18px;'
      onOpen={handleFocus}
      Trigger={Trigger}
    >
      {({ handleDropdownHide }) => (
        <OpenSearchInput
          onPlaceSelect={({ title: q = '', id: pid }) => {
            onChange({ name: 'searchQuery', value: { q, pid } })
            handleDropdownHide()
          }}
          placeholder={t('SearchForm::Where ?')}
          showNearbyExperiences
          showRecentSearches
          width='300px'
          zIndex={5}
          name='search'
          onChange={setInputValue}
          value={inputValue}
          renderTrigger={renderSearchTrigger}
          isDropdown={false}
          stateReducer={openSearchStateReducer}
        />
      )}
    </Dropdown>
  )
}

PlaceField.propTypes = {
  searchQuery: PropTypes.shape({
    q: PropTypes.string,
    pid: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
}
