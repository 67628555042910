import React, { useState, useCallback, memo, useEffect, useMemo } from 'react'
import { Modal, useModalContext } from '@vizeat/components/es6/components/Modal'
import { useToggle } from '@vizeat/components/es6/hooks'
import PropTypes from 'helpers/proptypes'
import { css } from 'styled-components'
import { Box } from '@vizeat/components/es6/components/Box'
import { useTranslation } from 'next-i18next'
import { useSearchQuery } from 'hooks/search/useSearchQuery'
import { StyledButton } from '../SearchForm/styled'
import { Grid } from '@vizeat/components/es6/components/Grid'
import { useUpdateRouterQuery } from 'hooks/router'
import { useRouter } from 'next/router'
import { FILTER_QUERY_KEYS, getSearchFiltersFromRouterQuery, getSearchParamsFromRouterQuery } from 'helpers/search'
import { Price } from './Price'
import { toCamelCase, toSnakeCase } from 'helpers/string'
import { Language } from './Language'
import { EventType } from './EventType'
import { FoodType } from './FoodType'
import { DietRestrictions } from './DietRestriction'
import { Amenities } from './Amenities'
import { InstantBooking } from './InstantBooking'
import { mapObject } from 'helpers/object'
import { Map } from 'immutable'
import { useGTMButtonClickOnce } from 'hooks/tracking'

const modalContentStyles = css`
  width: 100%;
  max-width: 1137px;
  border-radius: 0;

  ${({ theme }) => theme.media.tablet`
    border-radius: ${({ theme }) => theme.radii.xl};
  `}
`

function SubmitSearchButton({ searchParams, onClick }) {
  const { t } = useTranslation()
  const { query } = useRouter()
  const { handleModalHide } = useModalContext()

  const { selectData } = useSearchQuery(
    {
      ...getSearchParamsFromRouterQuery(query),
      ...searchParams,
    },
    {
      keepPreviousData: true, // required to not depend on the loading state
      allowPublicBookings: true,
    },
  )

  const totalCount = selectData(({ totalCount }) => totalCount)

  const handleClick = useCallback(() => {
    onClick()
    handleModalHide()
  }, [handleModalHide, onClick])

  return (
    <StyledButton green large onClick={handleClick}>
      {t('HomePage::SearchForm::Button::Search')} {typeof totalCount !== 'undefined' ? `(${totalCount})` : null}
    </StyledButton>
  )
}

SubmitSearchButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  searchParams: PropTypes.shape({}).isRequired,
}

const emptyState = FILTER_QUERY_KEYS.reduce((acc, qs) => {
  if (qs === 'mealtypes') return acc
  return {
    ...acc,
    [toCamelCase(qs)]: undefined,
  }
}, {})

export const SearchFilters = memo(function SearchFilters({ renderButton }) {
  const updateRouterQuery = useUpdateRouterQuery()
  const { query } = useRouter()
  const { t } = useTranslation()
  const [isVisible, { toggle: toggleModalVisibility, off: hideModal }] = useToggle()
  const handleGtmButtonClickOnce = useGTMButtonClickOnce({
    buttonId: 'filtersModal',
    buttonCta: 'Show filters',
  })

  const { selectData, searchQueryResult } = useSearchQuery(
    getSearchParamsFromRouterQuery(query, {
      includeFilters: false,
    }),
    {
      allowPublicBookings: true,
    },
  )

  const facets = selectData(({ facets }) => facets || Map({}))

  const initialState = useMemo(
    () => ({
      ...emptyState,
      ...getSearchFiltersFromRouterQuery(query),
    }),
    [query],
  )

  const [searchParams, setSearchParams] = useState(initialState)
  useEffect(() => {
    setSearchParams(initialState)
  }, [initialState])

  const handleChange = useCallback((value) => {
    setSearchParams((prev) => ({
      ...prev,
      ...value,
    }))
  }, [])

  const handleSubmit = useCallback(() => {
    const newQuery = mapObject(searchParams, (key, value) => [toSnakeCase(key), value])
    updateRouterQuery(newQuery)
  }, [searchParams, updateRouterQuery])

  const handleReset = useCallback(() => {
    setSearchParams(emptyState)
  }, [])

  const handleModalHide = useCallback(
    (_e, { trigger }) => {
      if (trigger === 'closeButton' || trigger === 'espacePress' || trigger === 'overlay') {
        // discard selections
        setSearchParams(initialState)
      }

      hideModal()
    },
    [hideModal, initialState],
  )

  const ButtonElement = useMemo(
    () =>
      renderButton({
        onClick: () => {
          handleGtmButtonClickOnce()
          toggleModalVisibility()
        },
        isLoading: searchQueryResult.isLoading,
      }),
    [handleGtmButtonClickOnce, renderButton, searchQueryResult.isLoading, toggleModalVisibility],
  )

  if (searchQueryResult.isLoading) return ButtonElement
  if (!searchQueryResult.isSuccess) return null

  return (
    <>
      {ButtonElement}

      <Modal
        isContentScrollable
        mobileFullScreen
        show={isVisible}
        onHide={handleModalHide}
        styles={modalContentStyles}
        isStickyHeader
        title={
          //! TODO use it as the default title style in the components-library
          <Box
            borderBottom={{ tablet: 'sm' }}
            borderColor={{ tablet: 'lightGray' }}
            textAlign={{ tablet: 'center' }}
            m={{ default: '4px 0 0', tablet: '8px 0 0 24px', desktop: '8px 12px 0 48px' }}
            pb={{ tablet: '20px' }}
            as='h2'
            fontWeight='bolder'
            fontSize='20px'
          >
            {t('Filters')}
          </Box>
        }
      >
        <Price min={searchParams.priceMin} max={searchParams.priceMax} onChange={handleChange} />

        <InstantBooking isSelected={!!searchParams.instantBooking} onChange={handleChange} />

        <Language
          facets={facets.get('user.spokenLanguages')}
          onChange={handleChange}
          selectedValues={searchParams.languages}
        />

        <EventType
          allowPublicBookings={
            typeof searchParams.allowPublicBookings === 'undefined' || searchParams.allowPublicBookings
          }
          allowPrivateBookings={searchParams.allowPrivateBookings}
          online={searchParams.online}
          onChange={handleChange}
        />

        <FoodType facets={facets.get('foodType')} selectedValues={searchParams.foods} onChange={handleChange} />

        <DietRestrictions facets={facets.get('diets')} selectedValues={searchParams.diets} onChange={handleChange} />

        <Amenities selectedValues={searchParams.amenities} onChange={handleChange} />

        <Grid
          p='18px 12px 24px'
          gap='8px'
          mt='24px'
          justifyContent='center'
          borderTop='sm'
          borderColor='lightGray'
          templateColumns='minmax(0, 180px) minmax(0, 180px)'
          position='sticky'
          zIndex='2'
          bottom='0'
          bg='white'
        >
          <SubmitSearchButton searchParams={searchParams} onClick={handleSubmit} />
          <StyledButton type='button' clearPrimary large onClick={handleReset}>
            {t('Forms::Reset')}
          </StyledButton>
        </Grid>
      </Modal>
    </>
  )
})

SearchFilters.propTypes = {
  renderButton: PropTypes.func.isRequired,
}
