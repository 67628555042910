import React from 'react'
import styled from 'styled-components'
import { Box } from '@vizeat/components/es6/components/Box'
import { Button } from '@vizeat/components/es6/components/Button'

export const StyledField = styled((props) => (
  <Box role='button' borderRight={{ tablet: 'sm' }} borderColor={{ tablet: 'darkGray' }} {...props} />
))`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding: 0;
  cursor: pointer;

  ${({ theme }) => theme.media.tablet`
    padding: 0 8px;
    text-align: center;
  `}

  &:before {
    content: '•';
    margin: 0 4px;
    display: ${({ hideSeparator }) => (hideSeparator ? 'none' : 'inline-block')};

    ${({ theme }) => theme.media.tablet`
      display: none;
    `}
  }
`

export const StyledPlaceholder = styled((props) => <Box as='span' {...props} />)`
  ${({ theme }) => theme.media.tablet`
    &:after {
      content: '--';
      font-size: 12px;
      color: ${({ theme }) => theme.colors.mediumGray};
      margin-left: 12px;
    }
  `}
`

export const StyledButton = styled(Button)`
  text-transform: uppercase;
  border-radius: ${({ theme }) => theme.radii.lg};
  color: ${({ theme, green }) => (green ? theme.colors.white : theme.colors.black)};
  font-weight: ${({ theme, green }) => (green ? theme.fontWeights.bolder : null)};
  font-size: 16px;
`

export const StyledSelectFilterBadge = styled.div``
