import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'helpers/proptypes'
import { GTM_EVENT_TYPES } from 'gtm'
import { Flex } from '@vizeat/components/es6/components/Flex'
import { Box } from '@vizeat/components/es6/components/Box'
import { Divider } from '@vizeat/components/es6/components/Divider'
import { Dropdown } from '@vizeat/components/es6/components/Dropdown'
import { IconChevronDown } from '@vizeat/components/es6/assets/icons'
import { StyledSection, StyledSectionTitle } from './styled'
import { usePlaylists } from 'hooks/search/usePlaylist'
import { SmallSpinner } from '@vizeat/components/es6/components/SmallSpinner'
import { useFilters } from 'hooks/prismic'
import { PlaylistEventsSection, PlaylistEventsSectionSkeleton } from 'components/shared/sections'

const MAX_EVENTS = 20

function PlaylistDropdownTrigger({ isFetching, title, playlistsSize, ...dropdownProps }) {
  if (isFetching) return <SmallSpinner color='eatwithOrange' display='block' />
  if (title) {
    return (
      <Flex
        flexDirection='row'
        fontSize={{ default: '24px', tablet: '28px' }}
        fontWeight='bolder'
        gap='4px'
        alignItems='center'
        color='eatwithOrange'
        css='cursor: pointer;'
        {...dropdownProps}
      >
        <Box>
          {title}
          <Divider m='0' color='lightGray' />
        </Box>

        {playlistsSize > 1 && <IconChevronDown size='24px' />}
      </Flex>
    )
  }

  return null
}

PlaylistDropdownTrigger.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  playlistsSize: PropTypes.number.isRequired,
  title: PropTypes.string,
}

PlaylistDropdownTrigger.defaultProps = {
  title: undefined,
}

export function TopDestinationsSection({
  isFetching,
  items,
  title,
  dateRangeFilters,
  mealtypeFilters,
  ...styledProps
}) {
  const searchFilters = useFilters({ dateRangeFilters, mealtypeFilters })
  const playlistsQueryResult = usePlaylists(items.map((item) => Number(item.playlist_id)))
  const arePlaylistsFetching = useMemo(
    () => isFetching || playlistsQueryResult.some(({ isFetching }) => isFetching),
    [playlistsQueryResult, isFetching],
  )

  const playlistsByIds = useMemo(
    () =>
      playlistsQueryResult.reduce((acc, { data, isError, isFetching }) => {
        if (isError || isFetching) return acc
        const playlistTitleFromPrismic = items.find((item) => Number(item.playlist_id) === data.id)?.playlist_title

        return {
          ...acc,
          [data.id]: {
            ...data,
            title: playlistTitleFromPrismic || data.title,
          },
        }
      }, {}),
    [items, playlistsQueryResult],
  )

  const playlists = useMemo(() => Object.values(playlistsByIds), [playlistsByIds])

  const getDefaultPlaylistId = useCallback(() => {
    const defaultPlaylist = items.find((item) => item.is_default_playlist) || items[0]
    return defaultPlaylist ? Number(defaultPlaylist.playlist_id) : null
  }, [items])

  const [selectedPlaylistId, setSelectedPlaylistId] = useState(getDefaultPlaylistId)
  useEffect(() => {
    setSelectedPlaylistId(getDefaultPlaylistId())
  }, [getDefaultPlaylistId])

  if (!arePlaylistsFetching && playlists.length === 0) return null

  const selectedPlaylist = playlistsByIds[selectedPlaylistId]

  return (
    <StyledSection hasCarousel {...styledProps}>
      <Flex
        flexDirection='row'
        gap='8px'
        mb='24px'
        alignItems='center'
        minHeight='41px' // set min-height to keep the same height between loading and final state and prevent CLS
      >
        <StyledSectionTitle
          css={`
            margin-bottom: 0;
          `}
        >
          {title}
          {selectedPlaylist?.title && (
            <Box as='span' position='absolute' left='-9999em'>
              {selectedPlaylist.title}
            </Box>
          )}
        </StyledSectionTitle>

        <Dropdown
          id='top-destinations-dropdown'
          isDisabled={playlists.length === 1}
          Trigger={
            <PlaylistDropdownTrigger
              playlistsSize={playlists.length}
              isFetching={arePlaylistsFetching}
              title={selectedPlaylist?.title}
            />
          }
        >
          {({ handleDropdownHide }) => (
            <Flex
              forwardedAs='ul'
              flexDirection='column'
              gap='16px'
              width='max-content'
              p='16px'
              mb='0'
              fontSize='18px'
              css={`
                list-style: none;
              `}
            >
              {playlists.map(({ id, title }) => {
                if (selectedPlaylistId === id) return null
                return (
                  <li
                    key={id}
                    onClick={() => {
                      setSelectedPlaylistId(id)
                      handleDropdownHide()
                    }}
                  >
                    {title}
                  </li>
                )
              })}
            </Flex>
          )}
        </Dropdown>
      </Flex>

      {arePlaylistsFetching && !selectedPlaylist && <PlaylistEventsSectionSkeleton containerStyles='padding: 0' />}

      {selectedPlaylist && (
        <PlaylistEventsSection
          containerStyles='padding: 0'
          searchQuery={selectedPlaylist.search}
          searchFilters={searchFilters}
          gtmType={`${GTM_EVENT_TYPES.HOME_TOP_DESTINATIONS}@@${selectedPlaylist.title}`}
          size={MAX_EVENTS}
          wrapInCarousel
        />
      )}
    </StyledSection>
  )
}

const filtersPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
)

TopDestinationsSection.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      playlist_id: PropTypes.string,
      is_default_playlist: PropTypes.bool,
    }),
  ).isRequired,
  title: PropTypes.string.isRequired,
  dateRangeFilters: filtersPropTypes,
  mealtypeFilters: filtersPropTypes,
  isFetching: PropTypes.bool,
}

TopDestinationsSection.defaultProps = {
  dateRangeFilters: undefined,
  mealtypeFilters: undefined,
  isFetching: false,
}
