import React, { useCallback, useMemo } from 'react'
import { IconFilters, IconSearch } from '@vizeat/components/es6/assets/icons'
import { Flex } from '@vizeat/components/es6/components/Flex'
import { Grid } from '@vizeat/components/es6/components/Grid'
import { RoundedIconWrapper } from 'components/shared/RoundedIconWrapper'
import { PlaceField } from './PlaceField'
import { GuestsField } from './GuestsField'
import { css } from 'styled-components'
import { MobileSearchFormModal } from '..'
import { useMediaQuery } from '@vizeat/components/es6/components/MediaQuery'
import { useSearchForm } from 'hooks/search/useSearchForm'
import { Box } from '@vizeat/components/es6/components/Box'
import { SearchFilters } from '../SearchFilters/SearchFilters'
import { useUpdateRouterQuery } from 'hooks/router'
import { SmallSpinner } from '@vizeat/components/es6/components/SmallSpinner'
import { useRouter } from 'next/router'
import dynamic from 'next/dynamic'
import { useToggle } from '@vizeat/components/es6/hooks/useToggle'
import PropTypes from 'helpers/proptypes'
import { getSearchFiltersFromRouterQuery } from 'helpers/search'

// disable DateField for SSR because it requires moment locale which is loaded on the client ...
const DateField = dynamic(() => import('./DateField').then((mod) => mod.DateField), { ssr: false })

const fieldsWrapperStyles = css`
  * {
    pointer-events: none;

    ${({ theme }) => theme.media.tablet`
      pointer-events: auto;
    `}
  }
`

function SearchButton({ isDisabled, type, onClick, ...styledProps }) {
  return (
    <RoundedIconWrapper
      display={{
        default: 'none',
        tablet: 'flex',
      }}
      forwardedAs='button'
      type={type}
      onClick={onClick}
      disabled={isDisabled}
      bg='eatwithOrange'
      color='white'
      width='40px'
      height='40px'
      css={`
        &[disabled] {
          opacity: 0.7;
        }

        &:hover:not([disabled]) {
          color: ${({ theme }) => theme.colors.white};
        }
      `}
      {...styledProps}
    >
      <IconSearch size='24px' />
    </RoundedIconWrapper>
  )
}

SearchButton.propTypes = {
  isDisabled: PropTypes.bool,
  type: PropTypes.oneOf(['submit', 'button']),
  onClick: PropTypes.func,
}

SearchButton.defaultProps = {
  isDisabled: false,
  type: 'submit',
  onClick: undefined,
}

export function SearchForm({
  name,
  withFilters,
  shouldUpdateRouter,
  shouldDisplaySearchIconOnMobile: shouldDisplaySearchIconOnMobileProp,
  formStyles,
}) {
  const { query } = useRouter()
  const isTabletOrHigher = useMediaQuery('tablet')
  const updateRouterQuery = useUpdateRouterQuery()
  const {
    handleSubmit,
    handleChange,
    state: { searchQuery, dateRange, numberOfSeats },
  } = useSearchForm()

  const searchParamsSize = useMemo(() => {
    const { mealtypes, ...filtersToCount } = getSearchFiltersFromRouterQuery(query) // exclude mealtypes as it's used outside the form
    return Object.values(filtersToCount).flat().length
  }, [query])

  const shouldDisplaySearchIconOnMobile = shouldDisplaySearchIconOnMobileProp && !isTabletOrHigher

  // The place is optional for playlists
  const isDisabled = query.playlist ? !dateRange.from && !dateRange.to && !numberOfSeats : !searchQuery.q

  const [isSearchModalVisible, { toggle }] = useToggle()

  const handlePlaceFieldChange = useCallback(
    ({ name, value }) => {
      if (shouldUpdateRouter) {
        updateRouterQuery({
          ...value,
          playlist: undefined,
          // reset map qs
          nelat: undefined,
          nelng: undefined,
          swlat: undefined,
          swlng: undefined,
        })
      }

      handleChange({ name, value })
    },
    [handleChange, shouldUpdateRouter, updateRouterQuery],
  )

  const renderFilters = useCallback(
    (display) => {
      if (!withFilters) return
      return (
        <SearchFilters
          renderButton={({ onClick, isLoading }) => (
            <RoundedIconWrapper
              type='button'
              onClick={onClick}
              forwardedAs='button'
              display={display}
              border='sm'
              width='40px'
              height='40px'
              position='relative'
              css={`
                &:hover:not([disabled]) {
                  color: ${({ theme }) => theme.colors.mediumGray};
                  ${({ theme }) => theme.media.tablet`
                   color: ${({ theme }) => theme.colors.red};
                  `}
                }
              `}
            >
              {isLoading ? <SmallSpinner color='eatwithOrange' /> : <IconFilters size='24px' />}
              {searchParamsSize > 0 && (
                <Box
                  bg='eatwithOrange'
                  color='white'
                  fontSize='8px'
                  position='absolute'
                  lineHeight='1.3'
                  borderRadius='full'
                  top='-6px'
                  right='-6px'
                  p='4px'
                  width='18px'
                  height='18px'
                >
                  {searchParamsSize}
                </Box>
              )}
            </RoundedIconWrapper>
          )}
        />
      )
    },
    [withFilters, searchParamsSize],
  )

  return (
    <>
      <Flex gap='8px' justifyContent='center' alignItems='center' width='100%' color='initial'>
        <Grid
          forwardedAs='form'
          onSubmit={handleSubmit}
          name={name}
          flex='1'
          gridTemplateColumns={{
            default: `1fr ${withFilters || shouldDisplaySearchIconOnMobile ? '40px' : ''}`,
            tablet: '1fr 36px',
          }}
          gap={{ default: '8px', tablet: 0 }}
          maxWidth='680px'
          width={{ tablet: '100%' }}
          bg='lighterGray'
          borderRadius='full'
          padding={{ default: '8px 12px 8px 18px', tablet: '4px 8px' }}
          fontSize={{ default: '14px', tablet: '16px' }}
          fontWeight='medium'
          alignItems='center'
          css={formStyles}
        >
          <Box
            display={{ default: 'flex', tablet: 'grid' }}
            flexWrap={{ default: 'wrap', medium: 'nowrap' }}
            padding={{ default: '0 4px', tablet: '0' }}
            gridTemplateColumns='1fr 1fr 150px'
            css={fieldsWrapperStyles}
            onClick={isTabletOrHigher ? undefined : toggle}
            textAlign='left'
          >
            <PlaceField searchQuery={searchQuery} onChange={handlePlaceFieldChange} />
            <DateField dateRange={dateRange} onChange={handleChange} />
            <GuestsField numberOfSeats={numberOfSeats} onChange={handleChange} />
          </Box>

          {isTabletOrHigher && <SearchButton isDisabled={isDisabled} />}

          {shouldDisplaySearchIconOnMobile && <SearchButton display='flex' type='button' onClick={toggle} />}

          {renderFilters({ default: 'flex', tablet: 'none' })}
        </Grid>

        {renderFilters({ default: 'none', tablet: 'flex' })}
      </Flex>

      {!isTabletOrHigher && <MobileSearchFormModal isVisible={isSearchModalVisible} onHide={toggle} />}
    </>
  )
}

SearchForm.propTypes = {
  withFilters: PropTypes.bool,
  shouldUpdateRouter: PropTypes.bool,
  shouldDisplaySearchIconOnMobile: PropTypes.bool,
  formStyles: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.func])),
  ]),
  name: PropTypes.string,
}

SearchForm.defaultProps = {
  withFilters: true,
  shouldUpdateRouter: true,
  shouldDisplaySearchIconOnMobile: false,
  formStyles: undefined,
  name: undefined,
}
