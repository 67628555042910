import React, { memo } from 'react'
import PropTypes from 'helpers/proptypes'
import { useTranslation } from 'next-i18next'
import { StyledFiltersSection, StyledTitle } from './styled'
import { Toggle } from '@vizeat/components/es6/components/Toggle'
import { Flex } from '@vizeat/components/es6/components/Flex'
import { gtmFilterSelected } from 'gtm'

export const InstantBooking = memo(function InstantBooking({ isSelected, onChange }) {
  const { t } = useTranslation()

  function handleChange(e) {
    onChange({ instantBooking: e.target.checked || undefined })
    gtmFilterSelected('instantbooking', e.target.checked)
  }

  return (
    <StyledFiltersSection>
      <Flex alignItems='center'>
        <StyledTitle
          as='label'
          htmlFor='instantBooking'
          css={`
            font-weight: 600;
            margin: 0 24px 0 0;
          `}
        >
          {t('Search::InstantBooking')}
        </StyledTitle>
        <Toggle id='instantBooking' checked={isSelected} onChange={handleChange} />
      </Flex>
    </StyledFiltersSection>
  )
})

InstantBooking.propTypes = {
  isSelected: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
}

InstantBooking.defaultProps = {
  isSelected: false,
}
