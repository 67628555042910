import { gtmFilterSelected } from 'gtm'
import { useCallback } from 'react'

export function useMultiFiltersToggle(selectedValues, { onChange, filterName, gtmFilterName }) {
  return useCallback(
    (e, value) => {
      const selectedValue = e?.target.name || value
      const nextValuesSet = new Set(selectedValues)

      if (nextValuesSet.has(selectedValue)) {
        nextValuesSet.delete(selectedValue)
      } else {
        nextValuesSet.add(selectedValue)
      }

      onChange({
        [filterName]: nextValuesSet.size > 0 ? [...nextValuesSet] : undefined,
      })

      gtmFilterSelected(gtmFilterName || filterName, [...nextValuesSet].join(','))
    },
    [filterName, gtmFilterName, onChange, selectedValues],
  )
}
